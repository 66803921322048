@import '../breakpoints.scss';

.form {
    max-width: min(calc(100% - 50px), 500px);
    margin: 0 auto;
    
    &__error-msg {
        font-size: 0.8rem;
        margin-bottom: 30px;

        @include bp-lg {
            font-size: 0.9rem;
        }
    }

    .form-row {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }

    .form-input {
        position: relative;

        &__border {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: rgba(#FFF, 0.5);
            transform: scaleX(0%);
            opacity: 0;
            transition: transform 600ms, opacity 400ms;
            transform-origin: left
        }

        label {
            display: block;
            text-align: left;
            text-transform: uppercase;
            font-size: 0.7em;
            letter-spacing: 0.5px;
            font-weight: 300;
            margin-bottom: 2px;
        }
    
        input,
        textarea {
            display: block;
            width: 100%;
            outline: none;
            border: none;
            background-color: rgba(#FFF, 0.1);
            color: currentColor;
            padding: 8px;
    
            &:focus {
                & + .form-input__border {
                    transform: scaleX(100%);
                    opacity: 1;
                }
            }
        }
    
        textarea {
            resize: vertical;
            min-height: 100px;
        }
    }

    &__actions {
        margin-top: 15px;

        .btn {
            transition: transform 100ms;
        }
    }
    
}