@import "../breakpoints.scss";

$aboutBg: #2c2c2c;

.about {
    position: relative;
    padding: 50px 0 0;
    background-color: $aboutBg;
    color: #FFF;
    text-align: center;
    z-index: 1;
    overflow: hidden;

    @include bp-lg {
        text-align: left;
        padding: 150px 0 50px;
    }

    &__container {
        overflow: hidden;
    }

    &__content {
        margin: 0 auto;
        background-color: rgba(255,255,255,0.05);
        padding: 30px 30px;
        box-shadow: 3px 4px 15px 0px rgba(0, 0, 0, 0.15);
        
        h2 {
            margin-bottom: 50px;
            
            @include bp-lg {
                display: inline-block;

                &::after {
                    margin-left: 0;
                }
            }
        }

    }

    &__img {
        position: relative;
        margin-top: 50px;

        @include bp-lg {
            margin-top: 0;
        }

        img {
            vertical-align: middle;
        }

        &::before,
        &::after {
            content: '';
            width: 100%;
            position: absolute;
            left: 0;
            background-color: $aboutBg;
            height: 12px;
    
            @include bp-lg {
                height: 30px;
            }
        }
    
        &::before {
            top: 0;
            margin-top: -1px;
            clip-path: polygon(0 0, 0% 100%, 100% 0); // Odd edge rendering fix
        }
    
        &::after {
            bottom: 0;
            margin-bottom: -1px;
            clip-path: polygon(0 0, 0% 100%, 100% 100%); // Odd edge rendering fix
        }
    }
}