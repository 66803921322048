@import "normalize.css";
@import "variables.scss";
@import "components/btn.scss";
@import "aos/dist/aos";

body, html {
    margin: 0;
    padding: 0;
}

body, html, input, textarea, button {
    font-family: 'Roboto', sans-serif;
    line-height: 1.3;
}

h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    letter-spacing: 1px;
}

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

.c-featured {
    color: $featured-color;
}

::selection {
    background-color: #444;
}

a:not([class]) { // default a hover behaviour 
    color: $featured-color;
    text-decoration: none;
    display: inline-block;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: left;
        transform: scaleX(0%);
        transition: transform 200ms;
    }

    &:hover {
        &::after {
            transform: scaleX(100%);
        }
    }
}

.heading-underline {
    position: relative;

    &::after {
        display: block;
        content: '';
        width: 15px;
        margin: 0 auto;
        height: 2px;
        background-color: currentColor;
        opacity: 0.8;
        margin-top: 5px;
    }

    &.to-left {
        margin: 0 0 auto 0;
    }
}

.heading-margin {
    margin-bottom: 60px;
}

.text-center {
    text-align: center;
}