@import "../breakpoints.scss";

 .projects {
    position: relative;
    z-index: 1;
    padding: 220px 0 60px;
    background-color: #1a1a1a;
    color: #FFF;

    @include bp-lg {
        padding: 300px 0 150px;
    }

    &__text {
        max-width: 510px;
        margin: 0 auto;
    }

    .project {
        min-height: 100%;
    }

    .item-list {
        margin-top: 50px;
    }
}