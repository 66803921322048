@import "../breakpoints.scss";
$stackBg: #2c2c2c;

.stack {
    position: relative;
    z-index: 2;
    background-color: $stackBg;

    &::after {
        content: '';
        background-color: $stackBg;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 100px;
        margin-top: -1px;
        clip-path: polygon(0 0, 0% 100%, 100% 0); // Odd edge rendering fix
    }

    h2 {
        margin-bottom: 60px;
    }

    &__container {
        position: relative;
        z-index: 3;
        background-color: #314e58;
        color: #FFF;
        padding: 30px;
        box-shadow: -2px -1px 10px 0 rgba(#000, 0.2);
        transform: translateY(160px);

        @include bp-lg {
            padding: 50px;
        }
    }
}