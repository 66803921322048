@import "../breakpoints.scss";

 .hobbies {
    position: relative;
    z-index: 1;
    padding: 1px 0 0 0;
    color: #FFF;
    background: linear-gradient(#1a1a1a 5%, #4c4c4c);
    padding: 5px 10px 200px;
    overflow: hidden;

    @include bp-lg {
        padding: 50px 50px 200px;
    }

    &__images {
        position: relative;
        margin: 50px auto 0 auto;

        img {
            width: 100%;
            vertical-align: middle;
            box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.4);
        }
    }

    .amp {
        font-size: 26px;
        font-weight: 700;
        text-align: center;
        margin: 30px 0;
    }

    &__item {
        display: block;
    }
}