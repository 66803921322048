@import "../utils.scss";
@import "../breakpoints.scss";

.banner {
    position: relative;
    background-color: #1a1a1a;
    min-height: 108vh;
    overflow: hidden;

    @include bp-lg {
        min-height: 120vh;
    }

    &__particles {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        canvas {
            transition: opacity 250ms;
            opacity: 0;

            &.loaded {
                opacity: 1;
            }
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        top: calc(50% - 4vh);
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFF;
        text-align: center;
        z-index: 2;
        font-size: 1.2rem;
        width: calc(100% - 50px);

        @include bp-lg {
            top: calc(50% - 10vh);
            font-size: 3rem;
        }

        h1 {
            font-size: inherit;
            font-weight: inherit;
            margin-bottom: 0.5rem;
        }

        p {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
        }
    }

    &__btn {
        @include resetbtn;
        cursor: pointer;
        position: relative;
        color: inherit;
        height: 30px;
        width: 100px;
        
        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 20px;
            height: 1px;
            top: 50%;
            background-color: currentColor;
            color: inherit;
            transition: transform 300ms;
        }

        &::before {
            left: 50%;
            transform-origin: top left;
            transform: translateY(6px) rotate(-40deg);
        }
        &::after {
            right: 50%;
            transform-origin: top right;
            transform: translateY(6px) rotate(40deg);
        }

        &:hover {
            &::before {
                transform: translateY(10px) rotate(-40deg);
            }

            &::after {
                transform: translateY(10px) rotate(40deg);
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -2px; //1px line fix
        left: 0;
        width: 100%;
        height: 8vh;
        background-color: #2c2c2c;
        z-index: 3;
        clip-path: polygon(0% 0%, 50% 95%, 100% 0%, 100% 100%, 0% 100%);

        @include bp-lg {
            height: 20vh;
        }
    }
}